import { AppNotificationForReadDto, NotificationDataPayload } from "types";
import { IconButton, MenuItem, Skeleton, Stack } from "@mui/material";
import { CardHeader, FooterText, IconText, NotificationCardContent, NotificationText, StyledCard } from "./NotificationItem.styled";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { PrimeChip, PrimeMenu } from "components/common";
import { DateUtils, FormatUtils } from "utils";
import React, { useState } from "react";
import { useMarkNotificationAsReadOrUnreadMutation, useNotificationsUtils } from "hooks";
import { StyledNotificationNumber } from "components/layout/notifications/ToggleSwitchButtons.styled";
import { AppPermissions } from "app-constants";
import { AuthorizationWrapper } from "components/permissions";

type NotificationItemProps = {
  notification?: AppNotificationForReadDto;
  isLoading?: boolean;
};

function NotificationItem({ notification, isLoading }: NotificationItemProps) {
  const { markNotificationAsReadOrUnread } = useMarkNotificationAsReadOrUnreadMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getNotificationTypeIcon, navigateToNotification } = useNotificationsUtils();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return (
      <StyledCard>
        <NotificationCardContent>
          <CardHeader direction='row'>
            <Stack direction='row' gap={1} alignItems='center'>
              <Skeleton variant='circular' width={30} height={30} />
              <Skeleton variant='text' width={100} height={20} />
            </Stack>
            <Stack direction='row' alignItems='center'>
              <IconButton>
                <Skeleton variant='rounded' width={10} height={30} />
              </IconButton>
            </Stack>
          </CardHeader>
          <NotificationText>
            <Skeleton variant='text' />
          </NotificationText>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack>
              <Skeleton variant='text' width={40} height={30} />
            </Stack>
            <Stack>
              <Skeleton variant='text' width={60} height={20} />
            </Stack>
          </Stack>
        </NotificationCardContent>
      </StyledCard>
    );
  }
  if (!notification) return null;
  return (
    <StyledCard>
      <NotificationCardContent>
        <CardHeader direction='row'>
          <Stack direction='row' gap={1} alignItems='center'>
            {getNotificationTypeIcon(notification.type)}
            <IconText onClick={() => navigateToNotification(notification?.data as NotificationDataPayload, notification?.category)}>
              {notification.title}
            </IconText>
          </Stack>
          <Stack direction='row' alignItems='center'>
            {!notification.readDate && (
              <StyledNotificationNumber
                sx={{
                  width: "10px",
                  height: "10px",
                  minWidth: "10px",
                  minHeight: "10px"
                }}
                variant='caption'
              />
            )}
            <AuthorizationWrapper permissions={[AppPermissions.AppNotificationManagement.Actions.MarkAsReadUnRead]}>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVertOutlinedIcon color='secondary' />
              </IconButton>
            </AuthorizationWrapper>
          </Stack>
        </CardHeader>
        <NotificationText>{notification.body}</NotificationText>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack>
            <PrimeChip label={FormatUtils.fromPascalToNormal(notification.category)} />
          </Stack>
          <Stack>
            <FooterText>{DateUtils.formatDate(notification.createdAt)}</FooterText>
          </Stack>
        </Stack>
      </NotificationCardContent>
      <PrimeMenu
        id='long-menu'
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              "&:before": {
                content: "''",
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
              }
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={async () => {
            await markNotificationAsReadOrUnread(notification.id);
            handleClose();
          }}
        >
          {notification.readDate ? "Mark as Unread" : "Mark as Read"}
        </MenuItem>
      </PrimeMenu>
    </StyledCard>
  );
}

export default NotificationItem;
