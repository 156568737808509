import { AppRoutes } from "router/AppRoutes";
import { type AppointmentStage, FirebaseNotificationPayload, type NotificationCategory, NotificationDataPayload, NotificationType } from "types";
import { NotificationIcon } from "components/icons";
import NotificationAlertIcon from "components/icons/NotificationAlertIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarUtils } from "utils";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { PrimeButton, SecondaryCard } from "components/common";
import { ToastOptions } from "react-toastify";

export const useNotificationsUtils = () => {
  const navigate = useNavigate();
  const navigateToNotification = (data?: NotificationDataPayload, category?: NotificationCategory) => {
    if (!data || !category) return;
    switch (category) {
      case "Doctor": {
        return navigate(`/${AppRoutes.doctors.root}/${AppRoutes.doctors.profile}/${data.DoctorId}`);
      }
      case "Appointment":
        switch (data.AppointmentStage as AppointmentStage) {
          case "PendingEvaluation":
            return navigate(`/${AppRoutes.appointments.root}/${AppRoutes.appointments.profile}/${data.AppointmentId}`);
          case "ActiveReport":
            return navigate(`/${AppRoutes.activeReports.root}/${AppRoutes.activeReports.profile}/${data.ActiveReportId}`);
          case "ArchivedReport":
            return navigate(`/${AppRoutes.archivedReports.root}/${AppRoutes.archivedReports.profile}/${data.ArchivedReportId}`);
          default:
            return navigate(`/${AppRoutes.appointments.root}/${AppRoutes.appointments.profile}/${data.AppointmentId}`);
        }
      case "AppointmentTask": {
        return navigate(`/${AppRoutes.appointments.root}/${AppRoutes.appointments.profile}/${data.AppointmentId}`);
      }
      case "ActiveReport": {
        return navigate(`/${AppRoutes.activeReports.root}/${AppRoutes.activeReports.profile}/${data.ActiveReportId}`);
      }
    }
  };

  const getNotificationTypeIcon = (notificationType: NotificationType) => {
    switch (notificationType) {
      case "None":
        return "🔵";
      case "Notification":
        return null;
      case "Reminder":
        return <NotificationIcon />;
      case "Alert":
        return <NotificationAlertIcon />;
      default:
        return "";
    }
  };

  const showFirebaseNotification = (payload: FirebaseNotificationPayload) => {
    const { notification, data } = payload;
    SnackbarUtils.custom(
      <Stack gap={2}>
        <Stack direction='row' alignItems='center'>
          <Stack direction='row' alignItems='center' gap={2}>
            {getNotificationTypeIcon(data.NotificationType)}
            <Typography variant='h6'>{notification.title}</Typography>
          </Stack>
          <Box position='absolute' top={5} right={5}>
            <IconButton onClick={() => SnackbarUtils.DismissToast("notification")}>
              <Close opacity={0.6} fontSize='large' />
            </IconButton>
          </Box>
        </Stack>
        <SecondaryCard>
          <Box>{notification.body}</Box>
        </SecondaryCard>
        <Stack alignSelf='self-end'>
          <PrimeButton
            sx={{
              backgroundColor: "#1B917D",
              "&:hover": {
                backgroundColor: "#1B917D"
              }
            }}
            color='secondary'
            onClick={() => {
              navigateToNotification(data, data.Category);
              SnackbarUtils.DismissToast("notification");
            }}
          >
            Open
          </PrimeButton>
        </Stack>
      </Stack>,
      {
        position: "top-right",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false,
        toastId: "notification",
        hideProgressBar: true, // Hide progress bar
        icon: false // Disable the default icon
      } as ToastOptions
    );
  };
  return {
    navigateToNotification,
    getNotificationTypeIcon,
    showFirebaseNotification
  };
};
