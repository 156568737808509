import { useTheme } from "@mui/material/styles";
import { Badge, Box, Divider, Drawer, IconButton, Stack, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import React, { useEffect, useState } from "react";
import { PrimeButton, PrimeSimpleBar } from "components/common";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import { NotificationItem, NotificationList, ToggleSwitchButtons } from "components/layout/notifications";
import { useGetNotificationsListQuery, useNotificationsUtils, useProfileQuery, useSetDeviceTokenMutation } from "hooks";
import { StyledNotificationNumber } from "./ToggleSwitchButtons.styled";
import { messaging, requestForToken } from "config";
import { onMessage } from "firebase/messaging";
import { FirebaseNotificationPayload } from "types";
function Notifications() {
  const [showUnread, setShowUnread] = useState(true);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { onFetchProfile } = useProfileQuery();
  const { data, fetchNextPage, isFetching, isLoading, refetch } = useGetNotificationsListQuery({
    showUnread
  });
  const { showFirebaseNotification } = useNotificationsUtils();
  const { setDeviceToken } = useSetDeviceTokenMutation();

  useEffect(() => {
    if (!open) {
      setShowUnread(true);
    }
  }, [open]);

  useEffect(() => {
    const init = async () => {
      onFetchProfile().then(async userDtoResponse => {
        const response = await requestForToken();
        await setDeviceToken(userDtoResponse?.data?.id ?? "", {
          deviceType: "Web",
          isActive: true,
          token: response
        });
      });

      onMessage(messaging, (payload: any) => {
        const response = payload as FirebaseNotificationPayload;
        showFirebaseNotification(response);
        refetch();
      });
    };
    init();
  }, []);

  return (
    <>
      <Box marginInline={2}>
        <IconButton onClick={() => setOpen(true)}>
          <Badge badgeContent={data?.pages?.[0]?.pagination.total || 0} color='error'>
            <NotificationsIcon
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                transition: "all .2s ease-in-out",
                color: theme.palette.text.secondary
              }}
            />
          </Badge>
        </IconButton>
        <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
          <Stack width='455px'>
            <Stack direction='row' padding='20px' justifyContent='space-between'>
              <Stack direction='row' gap={1} alignItems='center'>
                <Typography variant='h6'>Notifications</Typography>
                <StyledNotificationNumber variant='caption' sx={{ color: "#fff" }}>
                  {data?.pages?.[0]?.pagination?.total && data?.pages?.[0]?.pagination?.total > 100 ? <>99+</> : data?.pages?.[0]?.pagination.total}
                </StyledNotificationNumber>
              </Stack>
              <Stack direction='row'>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseOutlinedIcon color='secondary' />
                </IconButton>
              </Stack>
            </Stack>
            <Divider />
            <Stack marginInline={2} marginBlock='24px' justifyContent='space-between' direction='row'>
              <ToggleSwitchButtons
                onFilteredBy={value => {
                  setShowUnread(value === "unread");
                }}
                showUnread={showUnread}
              />
              <PrimeButton onClick={() => navigate(`/${AppRoutes.notifications.root}`)} variant='outlined' color='secondary' sx={{ backgroundColor: "#fff" }}>
                Notification Center
              </PrimeButton>
            </Stack>
            <Stack marginInline={2} sx={{ background: "#fff", padding: "16px", borderRadius: "16px" }}>
              {isLoading && (
                <Stack maxWidth='406px' border='1px solid #e9e9e9' borderRadius='16px' overflow='hidden'>
                  <PrimeSimpleBar style={{ maxHeight: "calc(100dvh - 230px)" }}>
                    {Array.from({ length: 20 }).map((_, index) => (
                      <NotificationItem key={index} isLoading />
                    ))}
                  </PrimeSimpleBar>
                </Stack>
              )}
              {!isLoading && data?.pages?.[0]?.pagination?.total && data?.pages?.[0]?.pagination?.total > 0 ? (
                <NotificationList
                  isFetchingNextPage={isFetching}
                  notifications={data}
                  fetchNextPage={async () => {
                    await fetchNextPage();
                  }}
                />
              ) : (
                <Typography variant='body1' sx={{ textAlign: "center", color: "#6B6B6B" }}>
                  No notifications found.
                </Typography>
              )}
            </Stack>
          </Stack>
        </Drawer>
      </Box>
    </>
  );
}

export default Notifications;
