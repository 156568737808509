import { ApiURLs, httpClient } from "api/core";
import { AppNotificationForReadDtoPaginatedList } from "types";

const URL = ApiURLs.notificationManagement;
async function getLatestNotifications(queryParams: string) {
  return await httpClient.get<AppNotificationForReadDtoPaginatedList>(`${URL}/latest-notification?${queryParams}`);
}

async function markNotificationAsReadOrUnread(notificationId: string) {
  return await httpClient.put<void>(`${URL}/${notificationId}/read-unread-notification`);
}

async function markAllNotificationsAsRead() {
  return await httpClient.put<void>("/mark-all-notification-as-read");
}
export const NotificationsService = {
  notifications: {
    request: getLatestNotifications,
    queryKey: "getLatestNotifications"
  },
  markAsReadOrUnread: {
    request: markNotificationAsReadOrUnread,
    mutationKey: "markNotificationAsReadOrUnread"
  },
  markAllAsRead: {
    request: markAllNotificationsAsRead,
    mutationKey: "markAllNotificationsAsRead"
  }
};
